<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Campaign Reporting</h2>
        <div class="row mb-3">
            <div class="col-12 text-right">
                <div class="form-inline">
                    <div class="form-group mr-1">
                        <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                    </div>
                   <div class="form-group mr-1">
                        <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                    </div>
                    <div class="form-group mr-1">
                        <v-select
                            v-on:keyup.enter="search"
                            style="width: 200px; background: white"
                            label="name"
                            v-model="filter.campaign"
                            placeholder="-- Search Campaigns --"
                            :reduce="option => option._id"
                            :filterable="false"
                            :options="campaigns"
                            @search="onSearch"
                        >
                            <template slot="no-options">
                                Search Campaigns..
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group mr-1">
                        <select v-on:keyup.enter="search" class="form-control" v-model="filter.client">
                            <option value="">"-- Select Client --"</option>
                            <option v-for="item in clients" :key="item" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <div  class="form-group mr-1">
                        <select v-on:keyup.enter="search" class="form-control" v-model="filter.status">
                            <option value="">"-- Select Status --"</option>
                            <option v-for="item in statuses" :key="item" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <button type="button" class="btn btn-primary mr-1" @click="search"><i class="fa fa-search" title="Search"></i> Search</button>
                </div>
            </div>
        </div>

        <div class="report" v-if="report">
            <div class="row stats mb-4">
                <div class="col-12 col-sm-4">
                   <!-- <div class="card">
                        <div class="card-body text-center">
                            <h4>{{Number(report.total||0).toLocaleString()}} {{ this.hitTitle }}</h4>
                            &lt;!&ndash;<h5>For {{new Date(filter.from).toLocaleDateString("en-US",{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}}</h5>&ndash;&gt;
                        </div>
                    </div>-->
                </div>
            </div>

            <div class="row hours mb-4">
                <div class="col-12">
                    <campaign-hourly-report :hit-title="this.hitTitle" :report="this.report" :filter="filter"></campaign-hourly-report>
                    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="spinner">
                            <b-spinner type="grow" label="Loading..." variant="primary"></b-spinner>
                            <h3>Fetching......</h3>
                        </div>
                        <div slot="no-more"><h3>No more results were found......</h3></div>
                        <div slot="no-results"><h3>No results were found......</h3></div>
                    </infinite-loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VSelect from 'vue-select'
    import CampaignHourlyReport from "@/components/CampaignHourlyReport";
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: 'CampaignReport',
        props:['user'],
        components:{
            InfiniteLoading,
            VSelect,
            CampaignHourlyReport
        },
        data: function () {
            return {
                action:'',
                campaign:null,
                item: {},
                chartdata:[],
                filter:{
                    to: '',
                    from: '',
                    campaign:"",
                    client: "",
                    status:""
                },
                report:null,
                providers:[],
                states:[],
                cities:[],
                campaigns: [

                ],
                infiniteId: +new Date(),
                page: 1,
                hitTitle:"Total Successful Hits",
                filterResults:[],
                clients:[],
                statuses:["Failed","Successful"]
            }
        },
        beforeRouteEnter:function(to, from, next){
            next((vm)=>{
                vm.search()
            })
        },
        created: function(){
            this.load();
        },
        methods:{
            onSearch(search, loading) {
                loading(true);
                this.campaignSearch(loading, search, this);
            },
            campaignSearch: function(loading, search, vm){
                //Load the campaign
                var request = {'apikey': vm.$root.apikey,filters:{}};
                request.query = search;

                axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`,{params:request}).then(function(resp){
                    vm.campaigns = (resp.data && resp.data.data)? resp.data.data : null;
                    loading(false);
                });
            },
            search: function(){
                this.page = 1;
                this.infiniteId += 1;
                //Load the campaign
                const request = {
                    'apikey': this.$root.apikey,
                    filters:{
                        from: new Date(),
                        to: new Date(),
                        page: this.page
                    }
                };

                if(this.filter.from) request.filters.from = this.filter.from.replace(/-/g,'/');
                if(this.filter.to) request.filters.to = this.filter.to.replace(/-/g,'/');

                if(this.filter.campaign){
                    request.filters.campaign = this.filter.campaign;
                    this.filter.client = "";
                }
                if(this.filter.client){
                    request.filters.client = this.filter.client;
                    this.filter.campaign = null;
                }
                if(this.filter.status){
                    request.filters.status = this.filter.status;
                    if(this.filter.status === "Failed"){
                        this.hitTitle = "Total Failed Hits";
                    }else{
                        this.hitTitle = "Total Successful Hits";
                    }
                }

                this.$root.preloader = true

                axios.get(`${this.$root.serverUrl}/admin/campaigns/report`,{params:request}).then(function(resp){
                    //Store the stats
                    this.report = {}
                    this.report = resp.data.data;
                    this.$root.preloader = false;
                }.bind(this));
            },
            infiniteHandler($state) {
                this.page += 1;
                //Load the campaign
                const request = {
                    'apikey': this.$root.apikey,
                    filters:{
                        from: new Date(),
                        to: new Date(),
                        page: this.page
                    }
                };

                if(this.filter.from) request.filters.from = this.filter.from.replace(/-/g,'/');
                if(this.filter.to) request.filters.to = this.filter.to.replace(/-/g,'/');

                if(this.filter.campaign){
                    request.filters.campaign = this.filter.campaign;
                    this.filter.client = "";
                }
                if(this.filter.client){
                    request.filters.client = this.filter.client;
                    this.filter.campaign = null;
                }
                if(this.filter.status){
                    request.filters.status = this.filter.status;
                    if(this.filter.status === "Failed"){
                        this.hitTitle = "Total Failed Hits";
                    }else{
                        this.hitTitle = "Total Successful Hits";
                    }
                }

                //this.$root.preloader = true

                axios.get(`${this.$root.serverUrl}/admin/campaigns/report`,{params:request}).then(function(resp){
                    $state.loaded();
                    if (resp.data && resp.data.data.length > 0) {
                        this.report.push(...resp.data.data);
                    } else {
                        $state.complete();
                    }
                }.bind(this)).catch(function(){
                    $state.complete();
                });
            },
            load: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                const dateString = [year, month, day].join('-');
                this.filter.from = dateString;
                this.filter.to = dateString;

                //if(this.filter.from) request.filters.from = this.filter.from;
                //if(this.filter.to) request.filters.to = this.filter.to;

                this.$root.preloader = true

                /*axios.get(`${this.$root.serverUrl}/admin/campaigns/list`,{params:request}).then(function(resp){
                    this.campaigns = (resp.data && resp.data.data)? resp.data.data : null;

                }.bind(this));*/

                axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`,{params:request}).then(function(resp){
                    this.clients = [""];
                    if(resp.data && resp.data.data){
                           this.clients = this.clients.concat(resp.data.data);
                    }
                    this.filter.client = "";
                    this.$root.preloader = false;
                }.bind(this));

                /*return axios.get(`${this.$root.serverUrl}/admin/campaigns/provider`, {params:request}).then(function(resp){
                    this.report = (resp.data && resp.data.data)? resp.data.data : null;
                    this.$root.preloader = false;
                }.bind(this));*/
            },
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 300px;
    }

    .d-center {
        display: flex;
        align-items: center;
    }

    .v-select .dropdown li {
        border-bottom: 1px solid rgba(112, 128, 144, 0.1);
    }

    .v-select .dropdown li:last-child {
        border-bottom: none;
    }

    .v-select .dropdown li a {
        padding: 10px 20px;
        width: 100%;
        font-size: 1.25em;
        color: #3c3c3c;
    }

    .v-select .dropdown-menu .active > a {
        color: #fff;
    }
</style>
