<template>
    <div>
        <table class="table table-hover table-responsive table-striped">
            <thead>
                <tr>
                    <th>Campaign Id</th>
                    <th>Campaign Name</th>
                    <th>Campaign Client</th>
                    <th>Campaign Status</th>
                    <th>{{ HitTitle }}</th>
                    <th>Chart</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in report" :key="item.campaign_id">
                    <td>{{ item.campaign_id }}</td>
                    <td>{{ item.campaign_name }}</td>
                    <td>{{ item.campaign_client}}</td>
                    <td>{{ item.campaign_status }}</td>
                    <td>{{ Number(item.campaign_total).toLocaleString() }}</td>
                    <td>
                        <button class="btn btn-info" @click="loadChat(item)">
                            <span class="fa fa-line-chart" title="Reload"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <b-modal ref="my-modal" size="xl" hide-footer :title="modalTitle">
            <div class="chart">
                <line-chart style="height: 450px" :data="chartData.data" area />
            </div>
        </b-modal>
    </div>
</template>

<script>
import {LineChart} from "dr-vue-echarts";
import axios from "axios";

    export default {
        name: "CampaignHourlyReport",
        props:{
            report: Array,
            HitTitle: String,
            filter: Object
        },
        components: {
            LineChart
        },
        data: function(){
            return {
                modalTitle:"",
                chartModal: false,
                chartData: {
                    data:[

                    ]
                }
            }
        },
        methods:{
            loadChat(obj) {
                this.searching = true;
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.filter.from) request.filters.from = this.filter.from.replace(/-/g,'/');
                if(this.filter.to) request.filters.to = this.filter.to.replace(/-/g,'/');

                if(this.filter.campaign){
                    request.filters.campaign = this.filter.campaign;
                    this.filter.client = "";
                }

                if(this.filter.client){
                    request.filters.client = this.filter.client;
                    this.filter.campaign = null;
                }

                if(this.filter.status){
                    request.filters.status = this.filter.status;
                }

                this.$root.preloader = true

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/reports/${obj.campaign_id}`, {params:request}).then((resp) => {
                    let data = (resp.data && resp.data.data) ? resp.data.data : [];

                    this.modalTitle = `Chart for ${obj.campaign_name} (${Number(obj.campaign_total).toLocaleString()} ${this.HitTitle})`
                    this.$refs['my-modal'].show()
                    this.chartModal = true;

                    this.chartData = {
                        data:[
                            {
                                name: obj.campaign_name,
                                "x-name": "Daily Hour",
                                "y-name": "Hits",
                                data: [

                                ]
                            }
                        ]
                    };

                    this.chartData.data[0].data = data;
                    this.$root.preloader = false;
                    this.searching = false;
                });
            },
            showChart: function(obj){
                this.chartData = {
                    data:[

                    ]
                };
                this.modalTitle = `Chart for ${obj.campaign_name} (${Number(obj.campaign_total).toLocaleString()} ${this.HitTitle})`
                this.$refs['my-modal'].show()
                this.chartModal = true;
                this.chartData = {
                    data:[
                        {
                            name: obj.campaign_name,
                            "x-name": "Daily Hour",
                            "y-name": "Hits",
                            data: [

                            ]
                        }
                    ]
                };
                for(const hour in obj.hourly){
                    this.chartData.data[0].data.push({label: `${hour}`, value:obj.hourly[hour]})
                }
            }
        }
    }
</script>

<style scoped>

</style>
